.slick-slide.slick-active.slick-center.slick-current{
    background-color: #101010!important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.1);
    transition-duration: 0s;
    /* border: 1px solid #3d648f; */
  }
  .slick-slide.slick-active.slick-current{
    background-color: #101010!important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* border: 2px solid #3d648f; */
  }
  
  .slick-dots li button:before{
    color:white !important;
    /* opacity: 0.8 !important; */
  }
  
  /* .slick-dots{
    bottom: 0rem !important;
  } */
  
  .slick-slide{
    overflow: hidden;
  }
  
  @media screen and (max-width : 1250px) {
    .slick-next {
      display: none !important;
    }
    .slick-prev {
      display: none !important;
    }
  }
  
  .slick-next {
    right: -15px !important;
  }
  .slick-prev {
    left: -15px !important;
  }
  .slick-next {
    display: none !important;
  }
  .slick-prev {
    display: none !important;
  }