body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.font-link {
  font-family: 'Poppins', sans-serif;
}


svg{
  width: 30rem;
  height: 30rem;
  position: absolute;
  top: 0;
  z-index: 190;
}
ellipse {
transform-origin: 50% 50%;
-webkit-transform-origin: 40% 30%;
-webkit-animation: spin 8s cubic-bezier(0.5, 0.5, 0.5, .5) infinite;
animation: spin 8s cubic-bezier(0.5, 0.5, 0.5, .5) infinite;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@media only screen and (max-width: 770px){
  .css-1j167di::before{
    width: 6rem !important;
    height: 2px !important;
    margin-top: 10px !important;
  }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.main-logo{
  animation: heartbeat 2s infinite;
}

.swiper-pagination { pointer-events: all !important; }

